import { ChromePicker } from "react-color";
import { useState } from "react";
import { ButtonBase } from "@mui/material";
import ColorInputProps from "./ColorInput.types";
import hexToRgba from "hex-to-rgba";

const ColorInput = (props: ColorInputProps) => {
  const { trigger, value, opacity, onChange } = props;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker((prev) => !prev);
  };

  const handleClose = () => {
    setDisplayColorPicker((prev) => false);
  };

  return (
    <div>
      <ButtonBase onClick={handleClick}>{trigger}</ButtonBase>
      {displayColorPicker ? (
        <div
          style={{
            position: "absolute",
            zIndex: "2"
          }}
        >
          <div
            style={{
              position: "fixed",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px"
            }}
            onClick={handleClose}
          />
          <ChromePicker
            color={hexToRgba(value ? value.toString() : "", opacity)}
            onChange={onChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorInput;
