import { fire } from "services";
import { nanoid } from "nanoid";
import { Page, Survey } from "interfaces";
import { QUESTION_TYPE } from "enums";
import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { useNavigate, useParams } from "react-router-dom";
import { Question } from "types";
import DesignPageView from "./DesignPage.view";
import DesignProps from "./DesignPage.types";

const DesignPage = (props: DesignProps) => {
  const { shortId, id } = useParams();
  const navigate = useNavigate();

  const [survey, setSurvey] = useImmer<Survey | undefined>(undefined);
  const [surveyInformation, setSurveyInformation] = useState({
    id: "",
    name: "",
    description: ""
  });
  const [page, setPage] = useState<Page | undefined>(undefined);
  const [nPages, setNPages] = useState<number | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState<number | undefined>(undefined);
  const [showSurvery, setShowSurvery] = useState(true);
  const [selectedElement, setSelectedElement] = useState<Question>();

  const handleOnClickAddElement = (addType: QUESTION_TYPE) => {
    let element: Question | undefined = undefined;

    if (addType === QUESTION_TYPE.TEXT) {
      element = {
        type: QUESTION_TYPE.TEXT,
        name: "New text",
        id: nanoid()
      };
    } else if (addType === QUESTION_TYPE.INPUT) {
      element = {
        type: QUESTION_TYPE.INPUT,
        name: "New input",
        id: nanoid()
      };
    } else if (addType === QUESTION_TYPE.RADIO) {
      element = {
        type: QUESTION_TYPE.RADIO,
        name: "New radio question",
        id: nanoid(),
        options: [
          { label: "Option 1", id: nanoid() },
          { label: "Option 2", id: nanoid() }
        ]
      };
    }

    if (page && element) {
      setSurvey((draft) => {
        if (draft) {
          draft.pages = draft.pages.map((p) =>
            p.id === page.id
              ? {
                  ...p,
                  questions: element ? [...p.questions, element] : p.questions
                }
              : p
          );
        }
      });
    }
  };

  const handleOnClickAddPage = () => {
    setSurvey((draft) => {
      if (draft) {
        draft.pages = [
          ...draft.pages,
          {
            id: nanoid(),
            name: "Page",
            description: "Desc",
            questions: []
          }
        ];
      }
    });

    setNPages((n) => (n ? n + 1 : 1));
    setPageNumber((n) => (n ? n + 1 : 1));
  };

  const handleOnClickDeletePage = () => {
    if (page && survey) {
      setSurvey((draft) => {
        if (draft) {
          draft.pages = draft.pages.filter((p) => p.id !== page.id);
        }
      });

      setNPages((n) => (n ? n - 1 : 0));
      setPageNumber((n) =>
        n && n > 1 ? n - 1 : n && nPages && nPages > 1 ? 1 : undefined
      );
    }
  };

  const handleOnClickNextPage = () => {
    if (pageNumber && nPages) {
      setPageNumber((n) => (n && n < nPages ? n + 1 : n));
    }
  };

  const handleOnClickPreviousPage = () => {
    if (pageNumber) {
      setPageNumber((n) => (n && n > 1 ? n - 1 : n));
    }
  };

  const handleOnClickDeleteElement = (
    element: Question,
    elementIdx: number
  ) => {
    if (page && survey) {
      setSurvey((draft) => {
        if (draft) {
          draft.pages = draft.pages.map((p) =>
            p.id === page.id
              ? {
                  ...p,
                  questions: p.questions.filter((q, idx) => idx !== elementIdx)
                }
              : p
          );
        }
      });
    }
  };

  const handleOnClickDuplicateElement = (
    element: Question,
    elementIdx: number
  ) => {
    if (page && survey) {
      setSurvey((draft) => {
        if (draft) {
          draft.pages = draft.pages.map((p) =>
            p.id === page.id
              ? {
                  ...p,
                  questions: [
                    ...p.questions.slice(0, elementIdx + 1),
                    { ...element, id: nanoid() },
                    ...p.questions.slice(elementIdx + 1)
                  ]
                }
              : p
          );
        }
      });
    }
  };

  const handleOnClickEditElement = (element: Question, elementIdx: number) => {
    setSelectedElement(element);
  };

  const handleOnChangeTextNameElement = (
    element: Question,
    elementIdx: number,
    name: string
  ) => {
    if (page && survey) {
      setSurvey((draft) => {
        if (draft) {
          draft.pages = draft.pages.map((p) =>
            p.id === page.id
              ? {
                  ...p,
                  questions: p.questions.map((q, idx) =>
                    idx === elementIdx ? { ...q, name } : q
                  )
                }
              : p
          );
        }
      });
    }
  };

  const handleOnClickSaveSurvey = () => {
    if (survey) {
      fire
        .setSurvey(survey)
        .then(() => {
          console.log("Survey saved");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleOnClickSettingsSurvey = () => {};

  const handleOnClickMoveUpElement = (
    element: Question,
    elementIdx: number
  ) => {
    if (page && survey) {
      setSurvey((draft) => {
        if (draft) {
          draft.pages = draft.pages.map((p) => {
            if (p.id === page.id) {
              const questions = p.questions;
              const newQuestions = [...questions];
              const temp = newQuestions[elementIdx];

              /** Only move if possible */
              if (elementIdx === 0) {
                return p;
              }

              newQuestions[elementIdx] = newQuestions[elementIdx - 1];
              newQuestions[elementIdx - 1] = temp;
              return {
                ...p,
                questions: newQuestions
              };
            } else {
              return p;
            }
          });
        }
      });
    }
  };

  const handleOnClickMoveDownElement = (
    element: Question,
    elementIdx: number
  ) => {
    if (page && survey) {
      setSurvey((draft) => {
        if (draft) {
          draft.pages = draft.pages.map((p) => {
            if (p.id === page.id) {
              const questions = p.questions;
              const newQuestions = [...questions];
              const temp = newQuestions[elementIdx];

              /** Only move if possible */
              if (elementIdx === newQuestions.length - 1) {
                return p;
              }

              newQuestions[elementIdx] = newQuestions[elementIdx + 1];
              newQuestions[elementIdx + 1] = temp;
              return {
                ...p,
                questions: newQuestions
              };
            } else {
              return p;
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (pageNumber && survey) {
      setPage(survey.pages[pageNumber - 1]);
    } else {
      setPage(undefined);
    }
  }, [pageNumber, survey]);

  useEffect(() => {
    if (id && shortId) {
      fire
        .getSurvey({
          id: `${shortId}/${id}`
        })
        .then((fireSurvey) => {
          setSurvey(() => fireSurvey);

          setSurveyInformation({
            id: fireSurvey.id,
            name: fireSurvey.name,
            description: fireSurvey.description
          });

          setPage(fireSurvey.pages[0]);
          setNPages(fireSurvey.pages.length);

          if (fireSurvey.pages.length > 0) {
            setPageNumber(1);
          } else {
            setPageNumber(undefined);
          }
        })
        .catch((error) => {
          navigate("/home");
        });
    }
  }, [id, navigate, setSurvey, shortId]);

  return (
    <DesignPageView
      page={page}
      nPages={nPages}
      pageNumber={pageNumber}
      showSurvey={showSurvery}
      surveyInformation={surveyInformation}
      onClickAddElement={handleOnClickAddElement}
      onClickAddPage={handleOnClickAddPage}
      onClickDeletePage={handleOnClickDeletePage}
      onClickNextPage={handleOnClickNextPage}
      onClickPreviousPage={handleOnClickPreviousPage}
      onClickDeleteElement={handleOnClickDeleteElement}
      onClickDuplicateElement={handleOnClickDuplicateElement}
      onClickSaveSurvey={handleOnClickSaveSurvey}
      onClickSettingsSurvey={handleOnClickSettingsSurvey}
      onClickEditElement={handleOnClickEditElement}
      onChangeTextName={handleOnChangeTextNameElement}
      onBlurElements={() => setSelectedElement(undefined)}
      selectedElement={selectedElement}
      onClickMoveDownElement={handleOnClickMoveDownElement}
      onClickMoveUpElement={handleOnClickMoveUpElement}
      openSettingsSurvey={true}
      onClickSettingsSurveyClose={() => {}}
      onClickSaveSettingsSurvey={() => {}}
      onChangeNewSurveyName={() => {}}
      onChangeNewSurveyDescription={() => {}}
      newSurveyName={""}
      newSurveyDescription={""}
    />
  );
};

export default DesignPage;
