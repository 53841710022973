import Fire from "./fire";
import Export from "./export";

const firebaseConfig = {
  apiKey: "AIzaSyBE8wHHlDmK7TytPOvnMBTDzNvROzeNAJ0",
  authDomain: "mapx-firebase.firebaseapp.com",
  projectId: "mapx-firebase",
  storageBucket: "mapx-firebase.appspot.com",
  messagingSenderId: "458117313512",
  appId: "1:458117313512:web:1fd230e2322033c8f4ea5d"
};

/** Default fire service  */
const fire = new Fire(firebaseConfig, process.env.REACT_APP_FIRE_ENV);
const exportService = new Export();

export { Fire, fire, exportService as export };
