import TextQuestionProps from "./TextQuestion.types";
import { Card, CardContent, Stack, Typography } from "@mui/material";

const TextQuestion = (props: TextQuestionProps) => {
  const { value, options } = props;

  return (
    <Card elevation={0}>
      <CardContent
        sx={{
          color: "white",
          backgroundColor: "#193e3c",
          position: "relative"
        }}
      >
        <Stack spacing={2}>
          <Typography
            sx={{ whiteSpace: "pre-line", fontSize: "14px", ...options }}
            variant="body1"
          >
            {value}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TextQuestion;
