import ButtonMui from "@mui/material/Button";
import ButtonProps from "./Button.types";
import { CircularProgress } from "@mui/material";

const Button = (props: ButtonProps) => {
  const { sx, value, children, loading, ...rest } = props;

  return (
    <ButtonMui
      variant="contained"
      sx={{ backgroundColor: "white", color: "black", ...sx }}
      {...rest}
    >
      {loading ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        children || value
      )}
    </ButtonMui>
  );
};

export default Button;
