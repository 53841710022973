import Stack from "@mui/material/Stack";
import SurveyContainerProps from "./SurveyContainer.types";

const SurveyContainer = (props: SurveyContainerProps) => {
  const { children } = props;

  return (
    <Stack
      // minWidth="180px"
      width="28%"
      bgcolor="#1c2c2b"
      position="relative"
      sx={{ userSelect: "none" }}
    >
      {children}
    </Stack>
  );
};

export default SurveyContainer;
