import Stack from "@mui/material/Stack";
import MapContainerProps from "./MapContainer.types";

const MapContainer = (props: MapContainerProps) => {
  const { children } = props;

  return <Stack flex={1}> {children}</Stack>;
};

export default MapContainer;
