import { useEffect, useRef, useState } from "react";
import { Unsubscribe } from "firebase/firestore";
import { fire } from "services";
import { Folder, Survey } from "interfaces";
import { useNavigate, useSearchParams } from "react-router-dom";
import DesignsPageProps from "./DesignsPage.types";
import DesignsPageView from "./DesignsPageView";

const DesignsPage = (props: DesignsPageProps) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [openAddFolder, setOpenAddFolder] = useState(false);
  const [openAddSurvey, setOpenAddSurvey] = useState(false);
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [newSurveyName, setNewSurveyName] = useState("");
  const [newSurveyId, setNewSurveyId] = useState("");
  const [newSurveyDescription, setNewSurveyDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [selectedFolderId, setSelectedFolderId] = useState("");

  const unsubscribeSurveys = useRef<void | Unsubscribe>();
  const unsubscribeFolders = useRef<void | Unsubscribe>();

  const handleOnError = () => {};

  const handleOnClickFolder = (id: string) => {
    setLoading(true);
    navigate(`?folderId=${id}`);
  };

  const handleOnClickAddFolder = () => {
    setOpenAddFolder(true);
  };

  const handleOnClickCreateFolder = () => {
    setLoading(true);
    fire
      .createFolder({
        uid: fire.getUser()?.uid ?? "",
        name: newFolderName
      })
      .then((doc) => {
        setSelectedFolderId(doc.id);
      })
      .finally(() => {
        setNewFolderName("");
        setOpenAddFolder(false);
        setLoading(false);
      });
  };

  const handleOnClickAddSurvey = () => {
    setOpenAddSurvey(true);
  };

  const handleOnClickCreateSurvey = () => {
    setLoading(true);
    fire
      .createSurvey({
        uid: fire.getUser()?.uid ?? "",
        shortId: "mapx",
        id: newSurveyId,
        folderId: selectedFolderId,
        name: newSurveyName,
        description: newSurveyDescription
      })
      .then(() => {
        setNewSurveyName("");
        setNewSurveyDescription("");
        setNewSurveyId("");
        setOpenAddSurvey(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnCloseAddFolder = () => {
    setOpenAddFolder(false);
  };

  const handleOnCloseAddSurvey = () => {
    setOpenAddSurvey(false);
  };

  const handleOnClickAnalyzeSurvey = (survey: Survey) => {
    navigate(`/analyze/${survey.id}`);
  };

  const handleOnClickEditSurvey = (survey: Survey) => {
    navigate(`/design/${survey.id}`);
  };

  const handleOnClickPublishSurvey = (survey: Survey) => {};

  const handleOnClickArchiveSurvey = (survey: Survey) => {};

  const handleOnClickDeleteSurvey = (survey: Survey) => {};

  useEffect(() => {
    unsubscribeFolders.current = fire.subscribeToFolders({
      uid: fire.getUser()?.uid ?? "",
      onSuccess: (folders) => {
        setFolders(folders);
      },
      onError: (error) => {
        handleOnError();
      }
    });
  }, []);

  useEffect(() => {
    unsubscribeSurveys.current = fire.subscribeToSurveys({
      uid: fire.getUser()?.uid ?? "",
      folderId: selectedFolderId,
      onSuccess: (docs) => {
        const sortedDocs = docs.sort(
          (a, b) => b.created.toMillis() - a.created.toMillis()
        );
        setSurveys(sortedDocs);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
      }
    });

    return () => {
      if (unsubscribeSurveys.current) unsubscribeSurveys.current();
    };
  }, [selectedFolderId]);

  useEffect(() => {
    const folderId = searchParams.get("folderId");

    if (folderId) {
      setSelectedFolderId(folderId);
    }
  }, [searchParams]);

  return (
    <DesignsPageView
      disabledAddSurvey={folders.length === 0}
      folders={folders}
      loading={loading}
      newFolderName={newFolderName}
      newSurveyDescription={newSurveyDescription}
      newSurveyId={newSurveyId}
      newSurveyName={newSurveyName}
      onChangeNewFolderName={setNewFolderName}
      onChangeNewSurveyDescription={setNewSurveyDescription}
      onChangeNewSurveyId={setNewSurveyId}
      onChangeNewSurveyName={setNewSurveyName}
      onClickAddFolder={handleOnClickAddFolder}
      onClickAddSurvey={handleOnClickAddSurvey}
      onClickAnalyzeSurvey={handleOnClickAnalyzeSurvey}
      onClickArchiveSurvey={handleOnClickArchiveSurvey}
      onClickCreateFolder={handleOnClickCreateFolder}
      onClickCreateSurvey={handleOnClickCreateSurvey}
      onClickDeleteSurvey={handleOnClickDeleteSurvey}
      onClickEditSurvey={handleOnClickEditSurvey}
      onClickFolder={handleOnClickFolder}
      onClickFolderClose={handleOnCloseAddFolder}
      onClickPublishSurvey={handleOnClickPublishSurvey}
      onClickSurveyClose={handleOnCloseAddSurvey}
      openAddFolder={openAddFolder}
      openAddSurvey={openAddSurvey}
      selectedFolder={selectedFolderId}
      surveys={surveys}
    />
  );
};

export default DesignsPage;
