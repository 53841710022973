import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeProps from "./HomePage.types";
import { fire } from "services";
import { Unsubscribe } from "firebase/firestore";
import { Survey } from "interfaces";

const HomePage = (props: HomeProps) => {
  const navigate = useNavigate();

  const [surveys, setSurveys] = useState<Survey[]>([]);

  const [selectedSurvey, setSelectedSurvey] = useState<Survey>();

  const unsubscribeSurveys = useRef<void | Unsubscribe>();

  const handleOnClickCreateSurvey = useCallback(() => {}, []);

  const handleOnClickSurvey = useCallback((survey: Survey) => {
    setSelectedSurvey(survey);
  }, []);

  const handleOnDoubleClickSurvey = useCallback(
    (survey: Survey) => {
      navigate(`/design/${survey.id}`);
    },
    [navigate]
  );

  return (
    <Stack>
      <Box padding={2} mt={2}>
        <Stack direction="row" justifyContent="space-between"></Stack>
      </Box>
    </Stack>
  );
};

export default HomePage;
