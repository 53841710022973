import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { SurveyViewPageProps } from "./SurveyPage.types";
import { Map } from "components";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MobileStepper,
  Typography
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import QuestionsList from "components/QuestionsList";
import { useMemo, useRef } from "react";
import { Close, RestartAlt, Send } from "@mui/icons-material";
import { SnackbarProvider } from "notistack";
import Logo from "components/Logo";

const SurveyPageView = (props: SurveyViewPageProps) => {
  const {
    answers,
    page,
    pageNumber,
    nPages = 0,
    showSurvey,
    onClickBackPage,
    onClickNextPage,
    onClickShowSurvey,
    mapSettings,
    onChangeInputQuestion,
    onChangeDropdownQuestion,
    onChangeDropdownMultiQuestion,
    onChangeRadioQuestion,
    onChangeRadioMapQuestion,
    onChangeRadioMultiQuestion,
    onRemoveRadioQuestion,
    onClickMapQuestionOption,
    onClickExitMarkerPlaceholder,
    onClickConfirmNewMarker,
    onClickConfirmUpdateMarker,
    onClickDeleteMarker,
    onClickSend,
    toolMode,
    mapQuestions,
    mapMarkers,
    markerPlaceholderColor,
    openSentDialog,
    onClickEndSurvey,
    onClickRestartSurvey,
    isLoadingSend,
    isLoadingFetch
  } = props;

  const scrollStack = useRef<HTMLDivElement>(null);

  const filteredMapMarkers = useMemo(
    () =>
      mapMarkers?.filter((m) =>
        page?.questions?.find((q) => q.id === m.mapQuestionId)
      ),
    [mapMarkers, page?.questions]
  );

  return (
    <>
      <Stack height="100dvh" width="100%" direction="row">
        {/* {showSurvey && ( */}
        <Stack
          minWidth={showSurvey ? "200px" : "0px"}
          width={showSurvey ? "30%" : "0px"}
          bgcolor="#1c2c2b"
          position="relative"
          sx={{ userSelect: "none" }}
          visibility={showSurvey ? "visible" : "hidden"}
        >
          <Stack
            spacing={2}
            padding={4}
            sx={{ overflowY: "scroll" }}
            ref={scrollStack}
          >
            <Stack alignItems="center" justifyContent="center">
              <Logo />
            </Stack>
            <Stack spacing={2} color="white">
              <Typography variant="h6" fontWeight="bold">
                {page?.name === "" ? `Sida ${pageNumber}` : page?.name}
              </Typography>
              <Typography variant="subtitle1">{page?.description}</Typography>
            </Stack>
            <Stack spacing={2}>
              <QuestionsList
                answers={answers}
                questions={page?.questions}
                onChangeInputQuestion={onChangeInputQuestion}
                onChangeDropdownQuestion={onChangeDropdownQuestion}
                onChangeDropdownMultiQuestion={onChangeDropdownMultiQuestion}
                onChangeRadioQuestion={onChangeRadioQuestion}
                onChangeRadioMapQuestion={onChangeRadioMapQuestion}
                onChangeRadioMultiQuestion={onChangeRadioMultiQuestion}
                onClickMapQuestionOption={onClickMapQuestionOption}
                onRemoveRadioQuestion={onRemoveRadioQuestion}
              />
            </Stack>
            <div style={{ minHeight: "80px" }} />
          </Stack>
          {pageNumber === nPages && (
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{
                position: "absolute",
                bottom: 70,
                left: 0,
                height: "40px",
                width: "100%",
                color: "white",
                fontSize: "16px",
                borderRadius: "20px"
              }}
            >
              <Stack
                sx={{
                  color: "white",
                  fontSize: "16px",
                  borderRadius: "20px",
                  backgroundColor: "rgba(65, 103, 100, 1)",
                  width: "50%",
                  minWidth: "180px"
                }}
              >
                <Button onClick={onClickSend} sx={{ color: "white" }}>
                  <Stack spacing={1} direction="row">
                    <Send />
                    <Typography variant="button">Skicka</Typography>
                  </Stack>
                </Button>
              </Stack>
            </Stack>
          )}
          <MobileStepper
            sx={{
              position: "absolute",
              backgroundColor: "rgba(65, 103, 100, 1)",
              height: "40px",
              color: "white",
              fontSize: "16px"
            }}
            activeStep={pageNumber ? pageNumber - 1 : undefined}
            variant="text"
            backButton={
              <Button
                size="small"
                sx={{ color: "white" }}
                onClick={() => {
                  onClickBackPage && onClickBackPage();
                  scrollStack.current?.scrollTo(0, 0);
                }}
                disabled={pageNumber === 1}
              >
                <KeyboardArrowLeft sx={{ width: "40px", height: "40px" }} />
              </Button>
            }
            nextButton={
              <Button
                size="small"
                sx={{ color: "white" }}
                onClick={() => {
                  onClickNextPage && onClickNextPage();
                  scrollStack.current?.scrollTo(0, 0);
                }}
                disabled={pageNumber === nPages}
              >
                <KeyboardArrowRight sx={{ width: "40px", height: "40px" }} />
              </Button>
            }
            steps={nPages}
          />
        </Stack>
        {/* )} */}
        <Stack flex={1}>
          <Map
            answers={answers}
            settings={mapSettings}
            showSurvey={showSurvey}
            onClickShowSurvey={onClickShowSurvey}
            onClickExitMarkerPlaceholder={onClickExitMarkerPlaceholder}
            onClickConfirmNewMarker={onClickConfirmNewMarker}
            onClickConfirmUpdateMarker={onClickConfirmUpdateMarker}
            onClickDeleteMarker={onClickDeleteMarker}
            toolMode={toolMode}
            questions={mapQuestions}
            markers={filteredMapMarkers}
            markerPlaceholderColor={markerPlaceholderColor}
          />
        </Stack>
        <Dialog
          open={!!openSentDialog}
          PaperProps={{
            sx: {
              color: "white",
              backgroundColor: "#1c2c2b"
            }
          }}
        >
          <DialogTitle>
            <Stack
              spacing={2}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Nästa steg</Typography>
              <img
                src="/logo.jpeg"
                alt="logo"
                style={{
                  width: "42px",
                  height: "42px",
                  borderRadius: "12px"
                }}
              />
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack spacing={4}>
              <Typography>
                Tack för att du deltog i undersökningen. Vill du avsluta* eller
                starta om?
              </Typography>
              <Typography fontSize="14px" color="lightgray">
                *om du avslutar så kommer du till mapx.se hemsida.
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              padding={2}
            >
              <Button
                sx={{ color: "black", backgroundColor: "white" }}
                onClick={onClickEndSurvey}
              >
                <Close />
                Avsluta
              </Button>
              <Button
                disabled={isLoadingSend}
                sx={{ color: "black", backgroundColor: "white" }}
                onClick={onClickRestartSurvey}
              >
                {isLoadingSend ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <>
                    <RestartAlt />
                    Starta om
                  </>
                )}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
        <SnackbarProvider autoHideDuration={4000} />
      </Stack>
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={isLoadingFetch ?? false}
      >
        <Stack
          bgcolor="rgba(0,0,0,0.8)"
          padding={4}
          spacing={4}
          borderRadius={6}
          alignItems="center"
        >
          <Logo />
          <CircularProgress color="inherit" />
          <Typography fontSize="20px" color="white">
            Laddar in...
          </Typography>
        </Stack>
      </Backdrop>
    </>
  );
};

export default SurveyPageView;
