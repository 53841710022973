import {
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { DesignsPageViewProps } from "./DesignsPage.types";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "components";
import {
  Archive,
  Delete,
  Edit,
  GraphicEq,
  People,
  Publish
} from "@mui/icons-material";

const DesignsPageView = (props: DesignsPageViewProps) => {
  const {
    disabledAddSurvey,
    folders,
    loading,
    newFolderName,
    newSurveyDescription,
    newSurveyId,
    newSurveyName,
    onChangeNewFolderName,
    onChangeNewSurveyDescription,
    onChangeNewSurveyId,
    onChangeNewSurveyName,
    onClickAddFolder,
    onClickAddSurvey,
    onClickAnalyzeSurvey,
    onClickArchiveSurvey,
    onClickCreateFolder,
    onClickCreateSurvey,
    onClickDeleteSurvey,
    onClickEditSurvey,
    onClickFolder,
    onClickFolderClose,
    onClickPublishSurvey,
    onClickSurveyClose,
    openAddFolder,
    openAddSurvey,
    selectedFolder,
    surveys
  } = props;

  return (
    <>
      <Stack flex={1} direction="row">
        <Stack minWidth="260px" bgcolor="white">
          <Stack padding="20px" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Mappar</Typography>
              <IconButton onClick={onClickAddFolder}>
                <AddIcon />
              </IconButton>
            </Stack>
            <List>
              {folders?.map((f) => (
                <ListItemButton
                  key={f.fireId}
                  selected={f.fireId === selectedFolder}
                  onClick={() => {
                    if (onClickFolder) onClickFolder(f.fireId);
                  }}
                >
                  {f.name}
                </ListItemButton>
              ))}
            </List>
          </Stack>
        </Stack>
        <Stack width="100%" bgcolor="#2e4a4b">
          <Stack color="white" padding="20px" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography>Formulär</Typography>
              <IconButton
                disabled={disabledAddSurvey}
                onClick={onClickAddSurvey}
              >
                <AddIcon sx={{ color: disabledAddSurvey ? "gray" : "white" }} />
              </IconButton>
            </Stack>
            <Divider />
            <Stack spacing={2}>
              {surveys?.length === 0
                ? "Tomt"
                : surveys?.map((s) => (
                    <Card key={s.fireId}>
                      <CardContent>
                        <Stack spacing={2}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Typography variant="h5">{s.name}</Typography>
                            <Stack direction="row" spacing={1}>
                              <Tooltip title="Analysera">
                                <IconButton
                                  onClick={() => {
                                    if (onClickAnalyzeSurvey)
                                      onClickAnalyzeSurvey(s);
                                  }}
                                  size="small"
                                >
                                  <GraphicEq />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Antal svar">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={1}
                                >
                                  <Typography sx={{ userSelect: "none" }}>
                                    {s.meta?.nAnswers}
                                  </Typography>
                                  <People />
                                </Stack>
                              </Tooltip>
                            </Stack>
                          </Stack>
                          <Typography variant="body1">
                            {s.description}
                          </Typography>
                          <Typography variant="body1">{`Id: ${s.id}`}</Typography>
                          <Typography variant="body1">{`FireId: ${s.fireId}`}</Typography>
                        </Stack>
                      </CardContent>
                      <CardActions>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <Stack direction="row" spacing={1}>
                            <Tooltip title="Redigera">
                              <IconButton
                                onClick={() => {
                                  if (onClickEditSurvey) onClickEditSurvey(s);
                                }}
                                size="small"
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Publisera">
                              <IconButton
                                onClick={() => {
                                  if (onClickPublishSurvey)
                                    onClickPublishSurvey(s);
                                }}
                                size="small"
                              >
                                <Publish />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <Tooltip title="Arkivera">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  if (onClickArchiveSurvey)
                                    onClickArchiveSurvey(s);
                                }}
                              >
                                <Archive />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Ta bort">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  if (onClickDeleteSurvey)
                                    onClickDeleteSurvey(s);
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                      </CardActions>
                    </Card>
                  ))}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!openAddFolder}
        onClose={onClickFolderClose}
      >
        <DialogTitle>
          <Typography>Skapa ny mapp</Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>Fyll i namn på din nya mapp</DialogContentText>
            <TextField
              autoFocus
              required
              id="name"
              name="email"
              label="Namn"
              fullWidth
              variant="standard"
              size="small"
              value={newFolderName}
              onChange={
                onChangeNewFolderName
                  ? (e) => onChangeNewFolderName(e.target.value)
                  : undefined
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button value="Avbryt" onClick={onClickFolderClose} />
          <Button
            disabled={loading}
            value="Skapa"
            onClick={onClickCreateFolder}
            loading={loading}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!openAddSurvey}
        onClose={onClickSurveyClose}
      >
        <DialogTitle>
          <Typography>Skapa nytt formulär</Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              Fyll i namn och beskrivning för ditt nya forumär
            </DialogContentText>
            <TextField
              autoFocus
              required
              id="id"
              name="id"
              label="Id"
              fullWidth
              variant="standard"
              size="small"
              value={newSurveyId}
              onChange={
                onChangeNewSurveyId
                  ? (e) => onChangeNewSurveyId(e.target.value)
                  : undefined
              }
            />
            <TextField
              autoFocus
              required
              id="name"
              name="name"
              label="Namn"
              fullWidth
              variant="standard"
              size="small"
              value={newSurveyName}
              onChange={
                onChangeNewSurveyName
                  ? (e) => onChangeNewSurveyName(e.target.value)
                  : undefined
              }
            />
            <TextField
              multiline
              autoFocus
              required
              id="description"
              name="description"
              label="Beskrivning"
              fullWidth
              variant="standard"
              size="small"
              maxRows={6}
              value={newSurveyDescription}
              onChange={
                onChangeNewSurveyDescription
                  ? (e) => onChangeNewSurveyDescription(e.target.value)
                  : undefined
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button value="Avbryt" onClick={onClickSurveyClose} />
          <Button
            disabled={loading}
            value="Skapa"
            onClick={onClickCreateSurvey}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DesignsPageView;
