import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ROUTES } from "./RouterManager.enums";
import RouterManagerProps from "./RouterManager.types";
import SessionManager from "components/SessionManager";
import ErrorPage from "pages/ErrorPage";
import AnalyzePage from "pages/AnalyzePage";
import LoginPage from "pages/LoginPage";
import NavLayout from "layouts/NavLayout";
import HomePage from "pages/HomePage";
import DesignPage from "pages/DesignPage";
// import ProfilePage from "pages/ProfilePage";
// import SuperAdminPage from "pages/SuperAdminPage";
import DesignsPage from "pages/DesignsPage";
import SurveyPage from "pages/SurveyPage";

const RouterManager = (props: RouterManagerProps) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<SessionManager />}>
          <Route element={<NavLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path={ROUTES.DESIGN} element={<DesignsPage />} />
            {/* <Route path={ROUTES.PROFILE} element={<ProfilePage />} />
            <Route path={ROUTES.SUPERADMIN} element={<SuperAdminPage />} />*/}
          </Route>
          <Route path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route path={ROUTES.DESIGN_SHORTID_ID} element={<DesignPage />} />
          <Route path={ROUTES.ANALYZE_SHORTID_ID} element={<AnalyzePage />} />
        </Route>
        <Route path={ROUTES.SURVEY_SHORTID_ID} element={<SurveyPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterManager;
