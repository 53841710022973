import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DropdownQuestionProps from "./DropdownQuestion.types";

const DropdownQuestion = (props: DropdownQuestionProps) => {
  const { options, id: value, onChange, name, multiple, settings } = props;

  return (
    <FormControl fullWidth>
      <InputLabel>{name}</InputLabel>
      <Select
        value={multiple ? value || [] : value || ""}
        onChange={(e) => {
          const { value } = e.target;

          if (multiple && settings?.max && value.length > settings.max) {
            return;
          }

          if (onChange) {
            onChange(value);
          }
        }}
        multiple={multiple}
        label={name}
        sx={{ backgroundColor: "lightgray" }}
      >
        {options?.map((x) => (
          <MenuItem value={x.id}>{x.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownQuestion;
