import Stack from "@mui/material/Stack";
import FullScreenProps from "./FullScreen.types";

const FullScreen = (props: FullScreenProps) => {
  const { children } = props;

  return (
    <Stack direction="row" width="100dvw" height="100dvh">
      {children}
    </Stack>
  );
};

export default FullScreen;
