import { useState } from "react";
import NavLayoutProps from "./NavLayout.types";
import NavLayoutView from "./NavLayout.view";
import { useNavigate } from "react-router-dom";
import { fire } from "services";

const NavLayout = (props: NavLayoutProps) => {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState("");

  const onClickItem = (props: { id: string; route: string }) => {
    const { id, route } = props;

    setSelectedItem(id);
    navigate(route);
  };

  const handleOnClickSignOut = () => {
    fire.signOut();
  };

  return (
    <NavLayoutView
      onClickSignOut={handleOnClickSignOut}
      onClickItem={onClickItem}
      selectedItem={selectedItem}
      name="User"
      company="MapX"
    />
  );
};

export default NavLayout;
