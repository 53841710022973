import LogoProps from "./Logo.types";

const Logo = (props: LogoProps) => {
  const { width = 52, height = 52 } = props;

  return (
    <img
      src="/logo.jpeg"
      alt="logo"
      width={`${width}px`}
      height={`${height}px`}
      style={{ borderRadius: "12px" }}
    />
  );
};

export default Logo;
