import Stack from "@mui/material/Stack";
import StepperProps from "./Stepper.types";
import { IconButton, Tooltip, Typography } from "@mui/material";
import {
  Add,
  Delete,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from "@mui/icons-material";

const Stepper = (props: StepperProps) => {
  const {
    disableAdd,
    disableDelete,
    edit,
    onClickAdd,
    onClickDelete,
    onClickNext,
    onClickPrevious,
    step = 0,
    steps = 0,
    text
  } = props;

  return (
    <Stack
      sx={{
        backgroundColor: "rgba(65, 103, 100, 1)",
        height: "50px",
        color: "white",
        width: "100%"
      }}
      alignItems="center"
      justifyContent="space-between"
      direction="row"
    >
      <Tooltip title="Föregående">
        <IconButton
          disabled={step <= 1}
          size="small"
          sx={{ color: "white" }}
          onClick={onClickPrevious}
        >
          <KeyboardArrowLeft sx={{ width: "36px", height: "36px" }} />
        </IconButton>
      </Tooltip>
      <Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          {edit && (
            <Tooltip placement="left" title="Ta bort">
              <IconButton
                disabled={disableDelete}
                size="small"
                sx={{ color: "white" }}
                onClick={onClickDelete}
              >
                <Delete sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Tooltip>
          )}
          <Stack>
            <Typography variant="body1" color="white" fontSize="14px">
              {text}
            </Typography>
            <Stack direction="row">
              <Typography variant="body1" color="white" fontSize="14px">
                {`${step} / ${steps}`}
              </Typography>
            </Stack>
          </Stack>
          {edit && (
            <Tooltip placement="right" title="Lägg till">
              <IconButton
                disabled={disableAdd}
                size="small"
                sx={{ color: "white" }}
                onClick={onClickAdd}
              >
                <Add sx={{ width: "20px", height: "20px" }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Tooltip title="Nästa">
        <IconButton
          disabled={step >= steps}
          size="small"
          sx={{ color: "white" }}
          onClick={onClickNext}
        >
          <KeyboardArrowRight sx={{ width: "36px", height: "36px" }} />
        </IconButton>
      </Tooltip>
    </Stack>
  );
};

export default Stepper;
