import { FILTER_COMPARISON } from "../AnalyzePage.types";
import {
  extractAllSurveyQuestions,
  mapMarkersToGeoJson
} from "../AnalyzePage.utils";
import { MapRef } from "react-map-gl";
import { MapVisualizerProps, MapVisualizerRef } from "./MapVisualizer.types";
import { QUESTION_TYPE } from "enums";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapVisualizerView from "./MapVisualizer.view";
import { useImmer } from "use-immer";
import { HeatmapSettings, LayerSettings } from "interfaces";

const MapVisualizer = forwardRef<MapVisualizerRef, MapVisualizerProps>(
  (props, ref) => {
    const {
      onClickDelete,
      onClickResize,
      onClickFilter,
      graphQuestionId,
      filters,
      globallyFilteredRespondents,
      survey,
      id
    } = props;

    const [data, setData] = useState<{
      type: string;
      features: any;
    }>();
    const [layerSettings, setLayerSettings] = useImmer<LayerSettings>({
      radius: 4,
      color: "#bb00ff",
      opacity: 0.4,
      strokeColor: "#bb00ff",
      strokeWidth: 2,
      strokeOpacity: 1
    });
    const [heatmapSettings, setHeatmapSettings] = useImmer<HeatmapSettings>({
      intensity: 2,
      radius: 20,
      opacity: 0.2
    });
    const [graphTitle, setGraphTitle] = useState("");
    const [filteredRespondents, setFilteredRespondents] = useState<
      {
        answers: any;
        meta: any;
        mapMarkers: any;
      }[]
    >([]);
    const [showHeatmap, setShowHeatmap] = useState(false);
    const [showHeatmapSettings, setShowHeatmapSettings] = useState(false);
    const [showData, setShowData] = useState(true);
    const [showLayerSettings, setShowLayerSettings] = useState(false);

    const mapRef = useRef<MapRef>(null);
    const hasMapFitted = useRef(false);

    const fitToScreen = useCallback(
      (hasFitted?: () => void) => {
        if (mapRef.current && data && data.features.length >= 2) {
          const mapCoordinates = data.features.map(
            (feature: any) => feature.geometry.coordinates
          );

          const bounds = new mapboxgl.LngLatBounds();
          mapCoordinates.forEach((coordinates: any) => {
            bounds.extend(coordinates);
          });

          mapRef.current.fitBounds(bounds, {
            padding: 20
          });

          if (hasFitted) hasFitted();
        }
      },
      [data]
    );

    const handleOnClickDelete = useCallback(() => {
      if (onClickDelete) {
        onClickDelete(id);
      }
    }, [id, onClickDelete]);

    const handleOnClickFilter = useCallback(() => {
      if (onClickFilter) {
        onClickFilter(id);
      }
    }, [id, onClickFilter]);

    const handleOnClickResize = useCallback(() => {
      if (onClickResize) {
        onClickResize(id);
      }
    }, [id, onClickResize]);

    const handleOnClickFitToScreenIcon = useCallback(() => {
      fitToScreen();
    }, [fitToScreen]);

    const handleOnClickHeatmapIcon = useCallback(() => {
      setShowHeatmap((prev) => {
        const newState = !prev;

        if (newState === false) setShowHeatmapSettings(false);

        return newState;
      });
    }, []);

    const handleOnClickHeatmapSettingsIcon = useCallback(() => {
      setShowHeatmapSettings((prev) => !prev);
      setShowLayerSettings(false);
    }, []);

    const handleOnClickLayerIcon = useCallback(() => {
      setShowData((prev) => {
        const newState = !prev;

        if (newState === false) setShowLayerSettings(false);

        return newState;
      });
    }, []);

    const handleOnClickLayerSettingsIcon = useCallback(() => {
      setShowLayerSettings((prev) => !prev);
      setShowHeatmapSettings(false);
    }, []);

    const handleOnChangeLayerSetting = useCallback(
      (key: string, value: string) => {
        setLayerSettings((draft) => {
          draft[key] = value;
        });
      },
      [setLayerSettings]
    );

    const handleOnChangeHeatmapSetting = useCallback(
      (key: string, value: string) => {
        setHeatmapSettings((draft) => {
          draft[key] = value;
        });
      },
      [setHeatmapSettings]
    );

    useEffect(() => {
      if (filters.length === 0) {
        setFilteredRespondents(globallyFilteredRespondents);
        return;
      }

      const filteredRespondents = globallyFilteredRespondents.filter((r) => {
        return filters.every((f) => {
          const questionResponse = r.answers.find(
            (a: any) => a.question.id === f.questionId
          );
          /** If the question is not answered, return false */
          if (questionResponse === undefined) return false;
          const { answer } = questionResponse;
          if (f.filterComparison === FILTER_COMPARISON.IS) {
            return answer === f.filterValue;
          }
          if (f.filterComparison === FILTER_COMPARISON.IS_NOT) {
            return answer !== f.filterValue;
          }
          if (f.filterComparison === FILTER_COMPARISON.CONTAINS) {
            return answer.includes(f.filterValue);
          }
          return false;
        });
      });
      setFilteredRespondents(filteredRespondents);
    }, [filters, globallyFilteredRespondents]);

    useEffect(() => {
      if (!survey || !graphQuestionId) return;

      /** Extract all page questions in single array */
      const questions = extractAllSurveyQuestions(survey);

      const graphQuestion = questions.find((q) => q.id === graphQuestionId);
      if (!graphQuestion) return;

      const { type, name } = graphQuestion;
      setGraphTitle(name);

      /** Conditionally map the question types */
      if (type === QUESTION_TYPE.MAP_MARKER) {
        const mapMarkers = filteredRespondents
          .map((respondent) => respondent.mapMarkers)
          .flat();

        const mapQuestionIds = mapMarkers.map(
          (mapMarker) => mapMarker.mapQuestionId
        );

        if (mapQuestionIds.every((id) => id !== undefined)) {
          const filteredMapMarkers = mapMarkers.filter(
            (mapMarker) => mapMarker.mapQuestionId === graphQuestionId
          );

          const mapData = mapMarkersToGeoJson(filteredMapMarkers);

          setData(mapData);
        } else {
          const mapData = mapMarkersToGeoJson(mapMarkers);
          setData(mapData);
        }
      }
    }, [filteredRespondents, graphQuestionId, survey]);

    useEffect(() => {
      if (!hasMapFitted.current)
        fitToScreen(() => {
          hasMapFitted.current = true;
        });
    }, [fitToScreen]);

    return (
      <MapVisualizerView
        data={data}
        layerSettings={layerSettings}
        graphId={graphQuestionId}
        graphTitle={graphTitle}
        filterCount={filters.length}
        showHeatmap={showHeatmap}
        showHeatmapSettings={showHeatmapSettings}
        showData={showData}
        showLayerSettings={showLayerSettings}
        heatmapSettings={heatmapSettings}
        onClickFilter={handleOnClickFilter}
        onClickDelete={handleOnClickDelete}
        onClickResize={handleOnClickResize}
        onClickFitToScreenIcon={handleOnClickFitToScreenIcon}
        onClickHeatmapIcon={handleOnClickHeatmapIcon}
        onClickHeatmapSettingsIcon={handleOnClickHeatmapSettingsIcon}
        onClickLayerIcon={handleOnClickLayerIcon}
        onClickLayerSettingsIcon={handleOnClickLayerSettingsIcon}
        onChangeLayerSetting={handleOnChangeLayerSetting}
        onChangeHeatmapSetting={handleOnChangeHeatmapSetting}
        mapRef={mapRef}
      />
    );
  }
);

export default MapVisualizer;
