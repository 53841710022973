import { DesignViewPageProps } from "./DesignPage.types";
import {
  Badge,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useState } from "react";
import {
  ArrowDownward,
  ArrowUpward,
  Close,
  ContentCopy,
  Edit,
  Save,
  Settings
} from "@mui/icons-material";
import { QUESTION_TYPE } from "enums";
import Stack from "@mui/material/Stack";
import Map from "components/Map";
import Logo from "components/Logo";
import FullScreen from "layouts/FullScreen";
import SurveyContainer from "layouts/SurveyContainer";
import MapContainer from "layouts/MapContainer";
import Stepper from "components/Stepper";
import Button from "components/Button";

const DesignPageView = (props: DesignViewPageProps) => {
  const {
    newSurveyDescription,
    newSurveyName,
    nPages,
    onBlurElements,
    onChangeNewSurveyDescription,
    onChangeNewSurveyName,
    onChangeTextName,
    onClickAddElement,
    onClickAddPage,
    onClickDeleteElement,
    onClickDeletePage,
    onClickDuplicateElement,
    onClickEditElement,
    onClickMoveDownElement,
    onClickMoveUpElement,
    onClickNextPage,
    onClickPreviousPage,
    onClickSaveSettingsSurvey,
    onClickSaveSurvey,
    onClickSettingsSurvey,
    onClickSettingsSurveyClose,
    openSettingsSurvey,
    page,
    pageNumber,
    selectedElement,
    showSurvey,
    surveyInformation
  } = props;

  const [hovered, setHovered] = useState("");

  return (
    <FullScreen>
      <SurveyContainer>
        <Stack direction="row" spacing={2} bgcolor="#416764" padding="20px">
          <Logo />
          <Stack direction="row" width="100%" alignItems="flex-start">
            <Stack flex={1}>
              <Typography
                variant="body1"
                color="white"
                maxHeight="100px"
                sx={{ overflowY: "scroll" }}
              >
                {surveyInformation?.name}
              </Typography>
              <Typography
                variant="body2"
                color="white"
                maxHeight="100px"
                sx={{ overflowY: "scroll" }}
              >
                {surveyInformation?.description}
              </Typography>
            </Stack>
            <Tooltip title="Spara">
              <IconButton
                onClick={() => {
                  onClickSaveSurvey && onClickSaveSurvey();
                }}
                size="small"
              >
                <Badge overlap="circular" color="primary" variant="dot">
                  <Save
                    sx={{
                      color: "white",
                      width: "18px"
                    }}
                  />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Inställningar">
              <IconButton
                onClick={() => {
                  onClickSettingsSurvey && onClickSettingsSurvey();
                }}
                size="small"
              >
                <Settings
                  sx={{
                    color: "white",
                    width: "18px"
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack
          flex={1}
          sx={{
            overflowY: "auto",
            padding: "20px"
          }}
          spacing={2}
        >
          {page?.questions.map((question, questionIdx) => {
            let QuestionComponent;

            if (question.type === QUESTION_TYPE.TEXT) {
              if (selectedElement && selectedElement.id === question.id) {
                QuestionComponent = (
                  <TextField
                    value={question.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      onChangeTextName &&
                        onChangeTextName(question, questionIdx, value);
                    }}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      style: {
                        color: "white"
                      }
                    }}
                    multiline
                  />
                );
              } else {
                QuestionComponent = (
                  <Typography variant="body2">{question.name}</Typography>
                );
              }
            } else if (question.type === QUESTION_TYPE.INPUT) {
              if (selectedElement && selectedElement.id === question.id) {
                QuestionComponent = (
                  <TextField
                    value={question.name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = event.target;
                      onChangeTextName &&
                        onChangeTextName(question, questionIdx, value);
                    }}
                    variant="standard"
                    fullWidth
                    inputProps={{
                      style: {
                        color: "white"
                      }
                    }}
                    multiline
                  />
                );
              } else {
                QuestionComponent = (
                  <Stack spacing={2}>
                    <Typography variant="body2">{question.name}</Typography>
                    <TextField
                      multiline
                      maxRows={6}
                      inputProps={{ sx: { color: "white" } }}
                    />
                  </Stack>
                );
              }
            } else if (question.type === QUESTION_TYPE.RADIO) {
              QuestionComponent = (
                <>
                  <Typography variant="body2">{question.name}</Typography>
                  <Stack>
                    {question.options.map((option) => (
                      <Typography variant="body2">{option.label}</Typography>
                    ))}
                  </Stack>
                </>
              );
            }

            const isHovered = hovered === question.id;

            return (
              <Paper
                key={question.id}
                elevation={0}
                sx={{
                  color: "white",
                  backgroundColor: isHovered ? "#416764" : "#193e3c",
                  padding: "12px",
                  position: "relative"
                }}
                onMouseOver={(e) => {
                  setHovered(question.id);
                }}
                onMouseOut={(e) => {
                  setHovered("");
                }}
                onDoubleClick={() => {
                  onClickEditElement &&
                    onClickEditElement(question, questionIdx);
                }}
                onBlur={() => {
                  onBlurElements && onBlurElements();
                }}
                onKeyDown={(ev: React.KeyboardEvent<HTMLInputElement>) => {
                  if (ev.key === "Enter") {
                    onBlurElements && onBlurElements();
                    ev.preventDefault();
                  }
                }}
              >
                {isHovered && (
                  <>
                    <Stack
                      direction="row"
                      sx={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <Tooltip title="Ta bort">
                        <IconButton
                          onClick={() => {
                            onClickDeleteElement &&
                              onClickDeleteElement(question, questionIdx);
                          }}
                          size="small"
                        >
                          <Close
                            sx={{
                              color: "white",
                              width: "18px"
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Duplicera">
                        <IconButton
                          onClick={() => {
                            onClickDuplicateElement &&
                              onClickDuplicateElement(question, questionIdx);
                          }}
                          size="small"
                        >
                          <ContentCopy
                            sx={{
                              color: "white",
                              width: "18px"
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ändra">
                        <IconButton
                          onClick={() => {
                            onClickEditElement &&
                              onClickEditElement(question, questionIdx);
                          }}
                          size="small"
                        >
                          <Edit
                            sx={{
                              color: "white",
                              width: "18px"
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{ position: "absolute", bottom: 0, right: 0 }}
                    >
                      <Tooltip title="Flytta ner">
                        <IconButton
                          disabled={questionIdx === page.questions.length - 1}
                          onClick={() => {
                            onClickMoveDownElement &&
                              onClickMoveDownElement(question, questionIdx);
                          }}
                          size="small"
                        >
                          <ArrowDownward
                            sx={{
                              color:
                                questionIdx === page.questions.length - 1
                                  ? "gray"
                                  : "white",
                              width: "18px"
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Flytta upp">
                        <IconButton
                          disabled={questionIdx === 0}
                          onClick={() => {
                            onClickMoveUpElement &&
                              onClickMoveUpElement(question, questionIdx);
                          }}
                          size="small"
                        >
                          <ArrowUpward
                            sx={{
                              color: questionIdx === 0 ? "gray" : "white",
                              width: "18px"
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </>
                )}
                <Box minHeight={isHovered ? "40px" : undefined}>
                  {QuestionComponent}
                </Box>
              </Paper>
            );
          })}
        </Stack>
        <Grid container padding="12px" spacing={2}>
          {[
            QUESTION_TYPE.TEXT,
            QUESTION_TYPE.RADIO,
            QUESTION_TYPE.INPUT,
            QUESTION_TYPE.DROPDOWN,
            QUESTION_TYPE.MAP_MARKER
          ].map((type) => (
            <Grid item>
              <Button
                disabled={pageNumber === undefined}
                onClick={() => onClickAddElement && onClickAddElement(type)}
                value={type}
                size="small"
              />
            </Grid>
          ))}
        </Grid>
        <Stepper
          text="Sida"
          steps={nPages}
          step={pageNumber}
          edit
          disableDelete={pageNumber === undefined}
          onClickAdd={onClickAddPage}
          onClickDelete={onClickDeletePage}
          onClickNext={onClickNextPage}
          onClickPrevious={onClickPreviousPage}
        />
      </SurveyContainer>
      <MapContainer>
        <Map />
      </MapContainer>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!openSettingsSurvey}
        onClose={onClickSettingsSurveyClose}
      >
        <DialogTitle>
          <Typography>Inställningar</Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <DialogContentText>
              Namn, beskrivning och kartans startvy
            </DialogContentText>
            <TextField
              autoFocus
              required
              id="name"
              name="name"
              label="Namn"
              fullWidth
              variant="standard"
              size="small"
              value={newSurveyName}
              onChange={
                onChangeNewSurveyName
                  ? (e) => onChangeNewSurveyName(e.target.value)
                  : undefined
              }
            />
            <TextField
              autoFocus
              required
              id="description"
              name="description"
              label="Beskrivning"
              fullWidth
              variant="standard"
              size="small"
              value={newSurveyDescription}
              onChange={
                onChangeNewSurveyDescription
                  ? (e) => onChangeNewSurveyDescription(e.target.value)
                  : undefined
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button value="Avbryt" onClick={onClickSettingsSurveyClose} />
          <Button
            disabled={false}
            value="Spara"
            onClick={onClickSaveSettingsSurvey}
            loading={false}
          />
        </DialogActions>
      </Dialog>
    </FullScreen>
  );
};

export default DesignPageView;
