import {
  Badge,
  ButtonBase,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import { MapVisualizerViewProps } from "./MapVisualizer.types";
import {
  // GeolocateControl,
  Layer,
  Map as MapMapbox,
  NavigationControl,
  Source
} from "react-map-gl";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import AspectRatioICon from "@mui/icons-material/AspectRatio";
import mapboxgl from "mapbox-gl";
import {
  Circle,
  FitScreen,
  HeatPump,
  Layers,
  Settings
} from "@mui/icons-material";
import ColorInput from "components/ColorInput";
import { useRef, useState } from "react";

const mapStyles = [
  { label: "Satellit", value: "mapbox://styles/mapbox/satellite-v9" },
  { label: "Vägar", value: "mapbox://styles/mapbox/streets-v11" },
  { label: "Utomhus", value: "mapbox://styles/mapbox/outdoors-v12" },
  {
    label: "Satellit vägar",
    value: "mapbox://styles/mapbox/satellite-streets-v12"
  },
  { label: "Mörk", value: "mapbox://styles/mapbox/dark-v11" },
  { label: "Ljus", value: "mapbox://styles/mapbox/light-v11" }
];

const token =
  "pk.eyJ1IjoiaW5vZGlubyIsImEiOiJjbG5mNWxuN3UwajY4MmtvaHh0dmE1dnBzIn0.3rvwbu7fkYOM9jRMxl7kwA";

const MapVisualizerView = (props: MapVisualizerViewProps) => {
  const {
    data,
    layerSettings,
    mapRef,
    graphTitle,
    filterCount,
    showHeatmap,
    showHeatmapSettings,
    showData,
    showLayerSettings,
    heatmapSettings,
    onClickDelete,
    onClickResize,
    onClickFilter,
    onClickFitToScreenIcon,
    onClickLayerIcon,
    onClickLayerSettingsIcon,
    onClickHeatmapIcon,
    onClickHeatmapSettingsIcon,
    onChangeLayerSetting,
    onChangeHeatmapSetting
  } = props;

  const [openLayersMeny, setOpenLayersMeny] = useState(false);
  const [mapStyle, setMapStyle] = useState(mapStyles[0].value);

  const layersButton = useRef(null);

  const handleOnCloseLayersMenu = () => {
    setOpenLayersMeny(false);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        margin: 1,
        backgroundColor: "rgba(0,0,0,0)",
        outline: "1px solid white",
        color: "white",
        height: "600px",
        padding: 2
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="100%"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          alignItems="flex-start"
          spacing={2}
        >
          <Typography fontSize={18}>{`${graphTitle}`}</Typography>
          <Stack
            bgcolor="rgba(255,255,255,0.1)"
            borderRadius="12px"
            direction="row"
            spacing={0}
          >
            <IconButton onClick={onClickResize}>
              <AspectRatioICon sx={{ color: "white" }} />
            </IconButton>
            <IconButton onClick={onClickDelete}>
              <DeleteIcon sx={{ color: "white" }} />
            </IconButton>
            <Badge badgeContent={filterCount} color="primary">
              <IconButton onClick={onClickFilter}>
                <FilterListIcon sx={{ color: "white" }} />
              </IconButton>
            </Badge>
          </Stack>
        </Stack>
        <Stack width="100%" height="100%" position="relative">
          <MapMapbox
            ref={mapRef}
            reuseMaps
            mapLib={mapboxgl}
            mapboxAccessToken={token}
            style={{ flex: 1 }}
            onRender={(event) => event.target.resize()}
            mapStyle={mapStyle}
          >
            <NavigationControl />
            <Source id="data" type="geojson" data={data}>
              {showHeatmap && (
                <Layer
                  id="heatmap-layer"
                  type="heatmap"
                  source="data"
                  paint={{
                    "heatmap-intensity": Number(heatmapSettings.intensity),
                    "heatmap-radius": Number(heatmapSettings.radius),
                    "heatmap-opacity": Number(heatmapSettings.opacity),
                    "heatmap-color": [
                      "interpolate",
                      ["linear"],
                      ["heatmap-density"],
                      0,
                      "rgba(0, 0, 255, 0)",
                      0.1,
                      "royalblue",
                      0.3,
                      "cyan",
                      0.5,
                      "lime",
                      0.7,
                      "yellow",
                      1,
                      "red"
                    ]
                  }}
                />
              )}
              {showData && (
                <Layer
                  id="data-layer"
                  type="circle"
                  source="data"
                  paint={{
                    "circle-radius": Number(layerSettings.radius),
                    "circle-color": layerSettings.color,
                    "circle-opacity": Number(layerSettings.opacity),
                    "circle-stroke-color": layerSettings.strokeColor,
                    "circle-stroke-width": Number(layerSettings.strokeWidth),
                    "circle-stroke-opacity": Number(layerSettings.strokeOpacity)
                  }}
                />
              )}
            </Source>
          </MapMapbox>
          <Stack
            position="absolute"
            left="12px"
            top="12px"
            spacing={1}
            alignItems="self-start"
          >
            <Tooltip title="Anpassa till data">
              <ButtonBase onClick={onClickFitToScreenIcon}>
                <FitScreen
                  sx={{
                    bgcolor: "white",
                    padding: 0.4,
                    borderRadius: "4px",
                    color: "black",
                    outline: "2px solid rgba(213, 209, 206, 0.8)",
                    width: "23px",
                    height: "23px"
                  }}
                />
              </ButtonBase>
            </Tooltip>
            <Stack direction="row" spacing={0.5}>
              <Tooltip
                title={showData ? "Dölj Data" : "Visa Data"}
                disableInteractive
              >
                <ButtonBase onClick={onClickLayerIcon}>
                  <Circle
                    sx={{
                      bgcolor: "white",
                      padding: 0.4,
                      borderRadius: "4px",
                      color: showData ? layerSettings.color : "black",
                      outline: "2px solid rgba(213, 209, 206, 0.8)",
                      width: "23px",
                      height: "23px"
                    }}
                  />
                </ButtonBase>
              </Tooltip>
              {showData && (
                <ButtonBase onClick={onClickLayerSettingsIcon}>
                  <Settings
                    sx={{
                      bgcolor: "white",
                      padding: 0.4,
                      borderRadius: "4px",
                      color: "gray",
                      outline: "2px solid rgba(213, 209, 206, 0.8)",
                      width: "22px",
                      height: "22px"
                    }}
                  />
                </ButtonBase>
              )}
              {showLayerSettings && (
                <Stack
                  direction="column"
                  spacing={0.5}
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "64px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    outline: "2px solid rgba(213, 209, 206, 0.8)",
                    padding: "8px",
                    color: "black",
                    zIndex: 1
                  }}
                  width="100px"
                >
                  <Stack>
                    <Typography fontSize="12px">Cirkel</Typography>
                    <Stack direction="row" alignItems="center">
                      <InputBase
                        sx={{ fontSize: "12px" }}
                        value={layerSettings.radius}
                        onChange={(e) => {
                          onChangeLayerSetting("radius", e.target.value);
                        }}
                      />
                      <ColorInput
                        value={layerSettings.color}
                        trigger={
                          <Typography fontSize="12px">
                            {layerSettings.color}
                          </Typography>
                        }
                        onChange={(color) => {
                          onChangeLayerSetting("color", color.hex);
                          onChangeLayerSetting(
                            "opacity",
                            color.rgb.a ? color.rgb.a.toString() : ""
                          );
                        }}
                        opacity={layerSettings.opacity}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography fontSize="12px">Kant</Typography>
                    <Stack direction="row" alignItems="center">
                      <InputBase
                        sx={{ fontSize: "12px" }}
                        value={layerSettings.strokeWidth}
                        onChange={(e) => {
                          onChangeLayerSetting("strokeWidth", e.target.value);
                        }}
                      />
                      <ColorInput
                        value={layerSettings.strokeColor}
                        trigger={
                          <Typography fontSize="12px">
                            {layerSettings.strokeColor}
                          </Typography>
                        }
                        onChange={(color) => {
                          onChangeLayerSetting("strokeColor", color.hex);
                          onChangeLayerSetting(
                            "strokeOpacity",
                            color.rgb.a ? color.rgb.a.toString() : ""
                          );
                        }}
                        opacity={layerSettings.strokeOpacity}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack direction="row" spacing={0.5}>
              <Tooltip
                title={showHeatmap ? "Dölj Heatmap" : "Visa Heatmap"}
                disableInteractive
              >
                <ButtonBase onClick={onClickHeatmapIcon}>
                  <HeatPump
                    sx={{
                      bgcolor: "white",
                      padding: 0.4,
                      borderRadius: "4px",
                      color: showHeatmap ? "#bb00ff" : "black",
                      outline: "2px solid rgba(213, 209, 206, 0.8)",
                      width: "23px",
                      height: "23px"
                    }}
                  />
                </ButtonBase>
              </Tooltip>
              {showHeatmap && (
                <ButtonBase onClick={onClickHeatmapSettingsIcon}>
                  <Settings
                    sx={{
                      bgcolor: "white",
                      padding: 0.4,
                      borderRadius: "4px",
                      color: "gray",
                      outline: "2px solid rgba(213, 209, 206, 0.8)",
                      width: "23px",
                      height: "23px"
                    }}
                  />
                </ButtonBase>
              )}
              {showHeatmapSettings && (
                <Stack
                  direction="column"
                  spacing={0.5}
                  sx={{
                    position: "absolute",
                    top: "0px",
                    left: "64px",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    outline: "2px solid rgba(213, 209, 206, 0.8)",
                    padding: "8px",
                    color: "black",
                    zIndex: 1
                  }}
                  width="100px"
                >
                  <Stack>
                    <Typography fontSize="12px">Radie</Typography>
                    <Stack direction="row" alignItems="center">
                      <InputBase
                        sx={{ fontSize: "12px" }}
                        value={heatmapSettings.radius}
                        onChange={(e) => {
                          onChangeHeatmapSetting("radius", e.target.value);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography fontSize="12px">Opacitet</Typography>
                    <Stack direction="row" alignItems="center">
                      <InputBase
                        sx={{ fontSize: "12px" }}
                        value={heatmapSettings.opacity}
                        onChange={(e) => {
                          onChangeHeatmapSetting("opacity", e.target.value);
                        }}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Typography fontSize="12px">Intensitet</Typography>
                    <Stack direction="row" alignItems="center">
                      <InputBase
                        sx={{ fontSize: "12px" }}
                        value={heatmapSettings.intensity}
                        onChange={(e) => {
                          onChangeHeatmapSetting("intensity", e.target.value);
                        }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
          {/* <Stack
            direction="row"
            position="absolute"
            right={"50px"}
            top={"10px"}
          >
            <ButtonBase onClick={() => setOpenLayersMeny(true)}>
              <Layers
                ref={layersButton}
                sx={{
                  bgcolor: "white",
                  padding: 0.4,
                  borderRadius: "4px",
                  color: "black",
                  outline: "1px solid lightgray"
                }}
              />
            </ButtonBase>
          </Stack> */}
          <Menu
            id="basic-menu"
            anchorEl={layersButton.current}
            open={openLayersMeny}
            onClose={handleOnCloseLayersMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}
          >
            {mapStyles.map((x) => (
              <MenuItem
                onClick={() => {
                  setMapStyle(x.value);
                  handleOnCloseLayersMenu();
                }}
                key={x.value}
              >
                {x.label}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default MapVisualizerView;
