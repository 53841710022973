import CardContent from "@mui/material/CardContent";
import RadioMultiQuestionProps from "./RadioMultiQuestion.types";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { ButtonBase } from "@mui/material";
import { Clear } from "@mui/icons-material";

const RadioMultiQuestion = (props: RadioMultiQuestionProps) => {
  const { name, options = [], id, onChange, onRemove } = props;

  return (
    <Card elevation={0}>
      <CardContent
        sx={{
          color: "white",
          backgroundColor: "#193e3c",
          position: "relative"
        }}
      >
        <Stack spacing={2}>
          <Typography sx={{ fontWeight: "bold" }} variant="body1">
            {name}
          </Typography>
          <FormControl>
            {options?.map((x) => (
              <FormControlLabel
                key={x.id}
                control={
                  <Radio
                    onClick={(e) => onChange && onChange(x.id)}
                    sx={{
                      color: "#ffffff",
                      "&.Mui-checked": {
                        color: "#4bc0b8"
                      }
                    }}
                    checked={id?.includes(x.id)}
                  />
                }
                label={x.label}
                value={x.id}
              />
            ))}
          </FormControl>
        </Stack>
        <Stack
          position="absolute"
          bottom={10}
          right={10}
          sx={{ opacity: 1, color: "rgba(255,0,0,0.4)" }}
        >
          {id && (
            <ButtonBase
              onClick={() => {
                if (onRemove) onRemove(id);
              }}
            >
              <Clear sx={{ width: "20px" }} />
            </ButtonBase>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default RadioMultiQuestion;
