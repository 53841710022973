export enum ROOT_ROUTES {
  SURVEY = "survey",
  LOGIN = "login",
  ANALYZE = "analyze",
  PROFILE = "profile",
  SUPERADMIN = "superadmin",
  DESIGN = "design",
  SETTINGS = "settings"
}

export enum ROUTES {
  HOME = "/",
  SURVEY_SHORTID_ID = `/${ROOT_ROUTES.SURVEY}/:shortId/:id`,
  LOGIN = `/${ROOT_ROUTES.LOGIN}`,
  ANALYZE_SHORTID_ID = `/${ROOT_ROUTES.ANALYZE}/:shortId/:id`,
  PROFILE = `/${ROOT_ROUTES.PROFILE}`,
  SUPERADMIN = `/${ROOT_ROUTES.SUPERADMIN}`,
  DESIGN = `/${ROOT_ROUTES.DESIGN}`,
  DESIGN_SHORTID_ID = `/${ROOT_ROUTES.DESIGN}/:shortId/:id`,
  SETTINGS = `/${ROOT_ROUTES.SETTINGS}`,
  ERROR = "*"
}
