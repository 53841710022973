import { useCallback, useEffect, useState } from "react";
import { Data, DataVisualizerProps, GRAPH_TYPE } from "./DataVisualizer.types";
import DataVisualizerView from "./DataVisualizer.view";
import {
  extractAllSurveyQuestions,
  getConditionQuestionResponseCountMap,
  getQuestionResponseCountMap
} from "../AnalyzePage.utils";
import { QUESTION_TYPE } from "enums";
import { FILTER_COMPARISON } from "../AnalyzePage.types";

const DataVisualizer = (props: DataVisualizerProps) => {
  const {
    onClickDelete,
    onClickResize,
    onClickFilter,
    graphQuestionId,
    filters,
    globallyFilteredRespondents,
    survey,
    type: selectedGraphType,
    id
  } = props;

  const [data, setData] = useState<Data[]>([]);
  const [graphTitle, setGraphTitle] = useState("");
  const [graphType, setGraphType] = useState<GRAPH_TYPE>(
    selectedGraphType ?? GRAPH_TYPE.BAR
  );
  const [filteredRespondents, setFilteredRespondents] = useState<
    {
      answers: any;
      meta: any;
    }[]
  >([]);

  useEffect(() => {
    if (filters.length === 0) {
      setFilteredRespondents(globallyFilteredRespondents);
      return;
    }

    const filteredRespondents = globallyFilteredRespondents.filter((r) => {
      return filters.every((f) => {
        const questionResponse = r.answers.find(
          (a: any) => a.question.id === f.questionId
        );
        /** If the question is not answered, return false */
        if (questionResponse === undefined) return false;
        const { answer } = questionResponse;
        if (f.filterComparison === FILTER_COMPARISON.IS) {
          return answer === f.filterValue;
        }
        if (f.filterComparison === FILTER_COMPARISON.IS_NOT) {
          return answer !== f.filterValue;
        }
        if (f.filterComparison === FILTER_COMPARISON.CONTAINS) {
          return answer.includes(f.filterValue);
        }
        return false;
      });
    });
    setFilteredRespondents(filteredRespondents);
  }, [filters, globallyFilteredRespondents]);

  useEffect(() => {
    if (!survey) return;

    /** Extract all page questions in single array */
    const questions = extractAllSurveyQuestions(survey);

    const graphQuestion = questions.find((q) => q.id === graphQuestionId);
    if (!graphQuestion) return;
    const { id, type, name } = graphQuestion;
    setGraphTitle(name);

    /** Conditionally map the question types */
    if (
      type === QUESTION_TYPE.RADIO ||
      type === QUESTION_TYPE.RADIO_MAP ||
      type === QUESTION_TYPE.DROPDOWN ||
      type === QUESTION_TYPE.RADIO_MULTI
    ) {
      const questionDataMap = getQuestionResponseCountMap(
        id,
        filteredRespondents
      );

      const answerData = Array.from(questionDataMap.entries()).map(
        ([label, value]) => ({
          label,
          value
        })
      );

      setData(answerData);
      if (!selectedGraphType) {
        setGraphType(GRAPH_TYPE.PIE);
      }
    } else if (
      type === QUESTION_TYPE.RADIO_CONDITION ||
      type === QUESTION_TYPE.DROPDOWN_CONDITION
    ) {
      const questionDataMap = getConditionQuestionResponseCountMap({
        questionId: id,
        conditionQuestionId: graphQuestion.conditionId,
        surveyRespones: filteredRespondents
      });

      const answerData = Array.from(questionDataMap.entries()).map(
        ([label, value]) => ({
          label,
          value
        })
      );

      setData(answerData);
      if (!selectedGraphType) {
        setGraphType(GRAPH_TYPE.BAR);
      }
    } else if (type === QUESTION_TYPE.DROPDOWN_MULTI_CONDITION) {
      const questionDataMap = getConditionQuestionResponseCountMap({
        questionId: id,
        conditionQuestionId: graphQuestion.conditionId,
        surveyRespones: filteredRespondents
      });

      const answerData = Array.from(questionDataMap.entries()).map(
        ([label, value]) => ({
          label,
          value
        })
      );

      setData(answerData);
      if (!selectedGraphType) {
        setGraphType(GRAPH_TYPE.PIE);
      }
    } else {
    }
  }, [filteredRespondents, graphQuestionId, selectedGraphType, survey]);

  const handleOnClickDelete = useCallback(() => {
    if (onClickDelete) {
      onClickDelete(id);
    }
  }, [id, onClickDelete]);

  const handleOnClickResize = useCallback(() => {
    if (onClickResize) {
      onClickResize(id);
    }
  }, [id, onClickResize]);

  const handleOnClickFilter = useCallback(() => {
    if (onClickFilter) {
      onClickFilter(id);
    }
  }, [id, onClickFilter]);

  return (
    <DataVisualizerView
      data={data}
      graphId={graphQuestionId}
      type={graphType}
      graphTitle={graphTitle}
      filterCount={filters.length}
      onClickDelete={handleOnClickDelete}
      onClickResize={handleOnClickResize}
      onClickFilter={handleOnClickFilter}
    />
  );
};

export default DataVisualizer;
