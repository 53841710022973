import { QUESTION_TYPE } from "enums";
import QuestionsListProps from "./QuestionsList.types";
import RadioQuestion from "components/RadioQuestion";
import InputQuestion from "components/InputQuestion";
import MapMarkerQuestion from "components/MapMarkerQuestion";
import RadioMultiQuestion from "components/RadioMultiQuestion";
import RadioMapQuestion from "components/RadioMapQuestion";
import DropdownQuestion from "components/DropdownQuestion";
import TextQuestion from "components/TextQuestion";

const QuestionsList = (props: QuestionsListProps) => {
  const {
    answers,
    questions,
    onChangeInputQuestion,
    onChangeRadioQuestion,
    onChangeRadioMapQuestion,
    onChangeRadioMultiQuestion,
    onClickMapQuestionOption,
    onChangeDropdownQuestion,
    onChangeDropdownMultiQuestion,
    onRemoveRadioQuestion
  } = props;

  if (!questions) return null;

  return questions.map((question, idx) => {
    const { type, name, id } = question;

    const answer = answers && answers[id] ? answers[id] : "";

    if (type === QUESTION_TYPE.TEXT) {
      return <TextQuestion key={id} value={name} options={question.options} />;
    } else if (type === QUESTION_TYPE.INPUT) {
      return (
        <InputQuestion
          key={id}
          name={name}
          onChange={(value) => {
            onChangeInputQuestion && onChangeInputQuestion(id, value);
          }}
          id={answer}
        />
      );
    } else if (type === QUESTION_TYPE.RADIO) {
      const { options } = question;
      return (
        <RadioQuestion
          key={id}
          name={name}
          options={options}
          onChange={(value) => {
            onChangeRadioQuestion && onChangeRadioQuestion(id, value);
          }}
          onRemove={() => {
            onRemoveRadioQuestion && onRemoveRadioQuestion([id]);
          }}
          id={answer}
        />
      );
    } else if (type === QUESTION_TYPE.RADIO_MAP) {
      const { options } = question;

      return (
        <RadioMapQuestion
          key={id}
          name={name}
          options={options}
          onChange={(value, map) => {
            onChangeRadioMapQuestion &&
              onChangeRadioMapQuestion(id, value, map);
          }}
          onRemove={() => {
            onRemoveRadioQuestion && onRemoveRadioQuestion([id]);
          }}
          id={answer}
        />
      );
    } else if (type === QUESTION_TYPE.RADIO_CONDITION) {
      const { conditions, conditionId } = question;

      if (!answers) return null;

      const conditionAnswer = answers[conditionId];
      const conditionQuestion = conditions.find(
        (condition) => condition.id === conditionAnswer
      );

      if (!conditionQuestion) return null;

      return (
        <RadioQuestion
          key={id}
          name={name}
          options={conditionQuestion.options}
          onChange={(value) => {
            onChangeRadioQuestion && onChangeRadioQuestion(id, value);
          }}
          onRemove={() => {
            onRemoveRadioQuestion && onRemoveRadioQuestion([id]);
          }}
          id={answer}
        />
      );
    } else if (type === QUESTION_TYPE.RADIO_MULTI) {
      const { options } = question;
      return (
        <RadioMultiQuestion
          key={id}
          name={name}
          options={options}
          onChange={(value) => {
            onChangeRadioMultiQuestion && onChangeRadioMultiQuestion(id, value);
          }}
          onRemove={() => {
            onRemoveRadioQuestion && onRemoveRadioQuestion([id]);
          }}
          id={answer}
        />
      );
    } else if (type === QUESTION_TYPE.MAP_MARKER) {
      const { options } = question;
      const nAnswers =
        answers && answers[id] ? Object.keys(answers[id]).length : 0;

      return (
        <MapMarkerQuestion
          key={id}
          name={name}
          options={options}
          onClick={(value) => {
            onClickMapQuestionOption && onClickMapQuestionOption(id, value);
          }}
          nAnswers={nAnswers}
        />
      );
    } else if (type === QUESTION_TYPE.DROPDOWN) {
      const { options } = question;

      return (
        <DropdownQuestion
          key={id}
          name={name}
          options={options}
          onChange={(value) => {
            if (typeof value === "string") {
              onChangeDropdownQuestion && onChangeDropdownQuestion(id, value);
            }
          }}
          id={answer}
        />
      );
    } else if (type === QUESTION_TYPE.DROPDOWN_MULTI) {
      const { options, settings } = question;

      return (
        <DropdownQuestion
          key={id}
          name={name}
          options={options}
          onChange={(value) => {
            if (Array.isArray(value)) {
              onChangeDropdownMultiQuestion &&
                onChangeDropdownMultiQuestion(id, value);
            }
          }}
          multiple
          settings={settings}
          id={answer}
        />
      );
    } else if (type === QUESTION_TYPE.DROPDOWN_CONDITION) {
      const { conditions, conditionId } = question;

      if (!answers) return null;

      const conditionAnswer = answers[conditionId];
      const conditionQuestion = conditions.find(
        (condition) => condition.id === conditionAnswer
      );

      if (!conditionQuestion) return null;

      return (
        <DropdownQuestion
          key={id}
          name={name}
          options={conditionQuestion.options}
          onChange={(value) => {
            if (typeof value === "string") {
              onChangeDropdownQuestion && onChangeDropdownQuestion(id, value);
            }
          }}
          id={answer}
        />
      );
    } else if (type === QUESTION_TYPE.DROPDOWN_MULTI_CONDITION) {
      const { conditions, conditionId, settings } = question;

      if (!answers) return null;

      const conditionAnswer = answers[conditionId];
      const conditionQuestion = conditions.find(
        (condition) => condition.id === conditionAnswer
      );

      if (!conditionQuestion) return null;

      return (
        <DropdownQuestion
          key={id}
          name={name}
          options={conditionQuestion.options}
          onChange={(value) => {
            if (Array.isArray(value)) {
              onChangeDropdownMultiQuestion &&
                onChangeDropdownMultiQuestion(id, value);
            }
          }}
          multiple
          settings={settings}
          id={answer}
        />
      );
    } else {
      return null;
    }
  });
};

export default QuestionsList;
